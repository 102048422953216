<script>
export default {
  name: 'App',
  components: {
    
  }
}
</script>

<template>
  <div id="container">
    <router-view/>
  </div>

  <div id="logo-container">
    <a href="https://www.byquincy.com">
      <img src="/quincy-logo.png" id="logo">
    </a>
  </div>
</template>

<style>
@import "./assets/css/base.css";
</style>
